import React,{Component,useRef} from 'react'
import { useLocation } from 'react-router-dom';
import PrintComponent from './PrintComponent'
import Checkout from './Checkout';
import ReactToPrint, { useReactToPrint } from 'react-to-print';


const Print = () => {
    const location = useLocation();
    const formData = location.state?.formData || {};
    console.log(formData);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    });
    return (
        <div>
            <div className='contact bg bg-fixed bg-overlay-black-70'>

                <div className='contact-inner mt-5'>

                    <div className='contact-title mt-5'>
                        <h3>Quick Purchase</h3>
                    </div>
                    <div className='contactlist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Print</li>
                        </ul>
                    </div>
                    <div> </div>
                </div>
              
            </div>
            <div className='container mt-5 '>
            <div className='d-flex justify-content-center' onClick={handlePrint}>
                <div className='pdf_button p-3' style={{cursor:'pointer'}} ><i class="fa-solid fa-print"></i> <span>Download</span></div> </div>
                    <div className='row p-md-5' ref={componentRef}>
                        
                        <div className='col-md-9 border border-black'>
                            <h5 className='text-center p-2  border-bottom'>ENQUIRY</h5>
                             <div className='row mt-2'>
                                   <div className='col-md-6 mt-2'>
                                    <b>
                                        <h5>From</h5>
                                        <div className='p-2 '>
                                        <span className=''>Door number 4/459-19,
                                        Amathur,
                                        Virudhunagar to Sivakasi main road.
                                       </span>
                                      </div>
                                      <div className='d-flex gap-2'><span><i class="fa-solid fa-mobile "></i></span><span> +91 8144540196</span></div>
                                      <div  className='d-flex gap-2'><span><i class="fa-regular fa-envelope "></i></span><span>karthibala003@gmail.com</span></div>
                                    </b>
                                   </div>
                                   <div className='col-md-6 mt-2'>
                                   <b>
                                        <h5>To</h5>
                                        <div className='p-2'>
                                        <span className=''>{formData.name}
                                       </span>
                                      </div>
                                      <div className='d-flex gap-2'><span><i class="fa-solid fa-mobile "></i></span><span> +91 {formData.contactNumber}</span></div>
                                      <span className=''>{formData.address}, {formData.city}. -{formData.pincode}
                                      </span>
                                    </b>
                                   </div>
                             </div>
                             <div className='row mt-2 p-3'>
                               <div className='table-container'>
                                    <table className='table table-bordered table-responsive'>
                                    <thead>
                                    <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                   </tr>
                                    </thead>
                                    <tbody>
                                   {formData.products && formData.products.map((item) => (
                                       <PrintComponent item={item} key={item.code}/>
                                   
                                  ))}
                                   <tr>
                                    <td colspan="3"></td>
                                    <td >Sub Total </td>
                                    <td colspan="2">₹ {formData.totalProductsAmount}</td>
                                 
                                  </tr>
                                   <tr>
                                    <td colspan="3"></td>
                                    <td >Packing charges (3%) </td>
                                    <td colspan="2">₹ {parseInt(formData.packCharges)}</td>
                                 
                                  </tr>
                                  <tr>
                                    <td colspan="3"></td>
                                    <td >Payable amount </td>
                                    <td colspan="2">₹ {parseInt(formData.paybleAmount)}</td>
                                 
                                  </tr>
                                 </tbody>
                                 
                                    </table>
                                    </div>
                             </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Print