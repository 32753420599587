import React from 'react';
import Nav from './components/Nav';
import Home from './components/Home';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Price from './components/Price';
import Payment from './components/Payment';
import Contact from './components/Contact';
import Footer from './components/Footer';
import QuickPurchase from './components/QuickPurchase';
import Checkout from './components/Checkout';
import About from './components/About';
import Terms from './components/Terms';
import Safety from './components/Safety';
import Icon from './components/Icon';
import Print from './components/Print';


function App() {
  return (
    <div>
      
      
      <BrowserRouter>
      <Nav/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/pricelist' element={<Price/>}/>
          <Route path='/payment' element={<Payment/>}/>
          <Route path='/contactus' element={<Contact/>}/>
          <Route path='aboutus' element={<About/>}/>
          <Route path='terms&conditions' element={<Terms/>}/>
          <Route path='safety' element={<Safety/>}/>
          <Route path='/quick-purchase' element={<QuickPurchase/>}/>
          <Route path='/checkout' element={<Checkout/>}/>
          <Route path='/print' element={<Print />} />
        </Routes>
        <Icon/>
        <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App