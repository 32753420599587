import React from 'react';
import '../styles/price.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import '../assets/Kani Agencies Price List.pdf'
import '@react-pdf-viewer/core/lib/styles/index.css';
import myPdf from '../assets/prpdf.pdf';
function Price() {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div>
      <div className='price bg bg-fixed bg-overlay-black-70'>

        <div className='price-inner mt-5'>
          <div className='price-title mt-5'>
            <h3>Price List</h3>
          </div>
          <div className='pricelist mt-4'>
            <ul>
              <li><i class="fa-solid fa-house"></i><span>Home</span></li>
              <li><i class="fa-solid fa-angles-right"></i></li>
              <li>Pages</li>
              <li><i class="fa-solid fa-angles-left"></i></li>
              <li>Price List</li>
            </ul>
          </div>

        </div>
      </div>
      <div className=' mx-1 mx-sm-2 mx-md-4 mt-3 mt-sm-4 mt-md-5 border pdf_frame'>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={myPdf} plugins={[defaultLayoutPluginInstance]} />
          </Worker>

        </div>
    </div>
  )
}

export default Price