import React, { useEffect, useState } from 'react'

const ProductTableComponents = ({ item, getQuantity, totalAmount }) => {

    const [totAmount, setTotAmount] = useState(0)
    const [oldQuantoty, setOldQuantity] = useState(0)

    useEffect(() => {
        const foundItem = totalAmount.find(cd => cd.code === item.code);

        if (foundItem) {
            setTotAmount(foundItem.price * foundItem.quantity)
            setOldQuantity(foundItem.quantity)
        } else {
            setTotAmount(0)
            setOldQuantity(0)
        }
    }, [totalAmount, oldQuantoty])

    return (
        <tr >
            <td className='fw-normal'>{item.code}</td>
            <td>{item.productName}</td>
            <td>{item.content}</td>
            <td className='text-danger fw-bolder'><s>{item.actualRate}</s></td>
            <td className=' text-success fw-bolder'>₹ {item.price}</td>
            <td><input type='number' value={oldQuantoty > 0 && oldQuantoty} className='form-control qtyInput' onChange={(e) => getQuantity(item, e.target.value)} /></td>
            <td>{totAmount > 0 && totAmount}</td>
        </tr>
        // <>

        //     <div className='col-lg-4 pb-3'>

        //         <div className='card ' style={{ width: "25rem" }}>
        //             <div className='row'>
        //                 <div className='col-lg-4 '>
        //                     <p>Image</p>
        //                 </div>
        //                 <div className='col-lg-8'>
        //                     <div><b><span>{item.code}</span><span>{item.productName}</span></b></div>
        //                     <div>{item.actualRate}/{item.content}</div>

        //                     <p>{item.price}</p>
        //                     <div className='d-flex'>
        //                         <div>
        //                             <input type='number' value={oldQuantoty > 0 && oldQuantoty} className='form-control qtyInput' onChange={(e) => getQuantity(item, e.target.value)} />
        //                         </div>
        //                         <div>
        //                          {totAmount > 0 && totAmount}
        //                         </div>
        //                     </div>

        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        // </>

    )
}

export default ProductTableComponents
