import React, { useEffect, useState } from 'react';
import '../styles/contact.css';
import { avChottaFance, bijili, bombCrackers, chakra,twoThirdFountainWedding,newArrivalAnaconda,avpremiumgiftboxes,twoThirdCardSeries, colorFaountain, colorMatches, eightFountain, electric, fancyCrackers, festival, fiveSriVijaiFancy, flashLight, flowerPots, fourAVDoubleEball, fourFouravfancy, fourHalfavfancy, fourSpecialFountain, ladduSpecialFountain, mega, newArrival, pencil, rockets, sparklers, speedMultiColorShot, threeHalfavfancy, twinkling, twoThirdFountain, twoavChottaFance, twoavarielfancy } from './ProductData';
import ProductTableComponents from './ProductTableComponents';
import { Link } from 'react-router-dom';

function QuickPurchase() {

    const [totalAmount, setTotalAmount] = useState([])
    const [totalProductsAmount, setTotalProductsAmount] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    useEffect(() => {
        const oldData = JSON.parse(localStorage.getItem('productData')) || [];
        setTotalProducts(oldData.length)
        let productTotalPrice = 0;
        oldData && oldData.map((nd) => {
            productTotalPrice += nd.quantity * nd.price;
        })
        setTotalProductsAmount(productTotalPrice)

        setTotalAmount(oldData)
    }, [])

    function getQuantity(item, value) {

        const existingData = JSON.parse(localStorage.getItem('productData')) || [];

        if (value > 0 && parseInt(value)) {
            // Retrieve existing data from localStorage

            // Check if the product details already exist in the data
            const existingProductIndex = existingData.findIndex(ed => ed.code === item.code);

            if (existingProductIndex !== -1) {
                // Product details exist, update the quantity
                existingData[existingProductIndex].quantity = parseInt(value);
            } else {
                // Product details do not exist, insert a new object
                const newProduct = {
                    ...item,
                    quantity: parseInt(value),
                    // Add other product details here
                };
                existingData.push(newProduct);
            }
            // Save the updated data back to localStorage
            localStorage.setItem('productData', JSON.stringify(existingData));


            const newData = JSON.parse(localStorage.getItem('productData')) || [];

            let productTotalPrice = 0;
            newData && newData.map((nd) => {
                productTotalPrice += nd.quantity * nd.price;
            })
            setTotalProductsAmount(productTotalPrice)
            setTotalProducts(newData.length)
            setTotalAmount(newData)
        } else {
            const updatedData = existingData.filter(ed => ed.code !== item.code);

            localStorage.setItem('productData', JSON.stringify(updatedData));
            const newData = JSON.parse(localStorage.getItem('productData')) || [];
            let productTotalPrice = 0;
            newData && newData.map((nd) => {
                productTotalPrice += nd.quantity * nd.price;
            })
            setTotalProducts(newData.length)
            setTotalProductsAmount(productTotalPrice)
            setTotalAmount(updatedData)
        }

    }


   
    return (
        <div>
            <div className='contact bg bg-fixed bg-overlay-black-70'>

                <div className='contact-inner mt-5'>
                    <div className='contact-title mt-5'>
                        <h3>Quick Purchase</h3>
                    </div>
                    <div className='contactlist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Quick Purchase</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='px-2 px-sm-3 px-md-4 px-lg-5 pb-5 pt-5 quick_purchase'>
                <div className='px-3 common_bgred row fw-bold product-sticky'>
                    <div className='col-12 col-sm-12 col-md-6 py-2 text-dark text-center text-sm-center text-md-end pe-0 pe-sm-0 pe-md-5'>Total Products : <span>{totalProducts && totalProducts}</span></div>
                    <div className='col-12 col-sm-12 col-md-6 py-2 text-dark text-center text-sm-center text-md-start'>Overall Total  : <span>{totalProductsAmount && totalProductsAmount} INR</span></div>
                </div>
                <div className='mt-3'> 
                    <h3 className='fw-bold lead secondary_color'><u>FLASH LIGHT CRACKERS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                        {/* <div>
                            <div className='row'>
                                {flashLight && flashLight.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </div>
                        </div> */}
                    </div>


                </div>
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>BIJILI CRACKERS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bijili && bijili.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {bijili && bijili.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>PENCIL</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pencil && pencil.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {pencil && pencil.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>MEGA PENCIL</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mega && mega.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {mega && mega.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>CHAKKARAMS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chakra && chakra.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {chakra && chakra.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>TWINKLING STAR</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {twinkling && twinkling.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {twinkling && twinkling.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>FLOWER POTS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {flowerPots && flowerPots.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {flowerPots && flowerPots.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>COLOUR FOUNTAIN MEGA </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colorFaountain && colorFaountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {bijili && bijili.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>2 3/4" FOUNTAIN (5 Pcs)</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {twoThirdFountain && twoThirdFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {twoThirdFountain && twoThirdFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>2 3/4" WEDDING SERIES(CRACKLING WITH COLOUR)  </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {twoThirdFountainWedding && twoThirdFountainWedding.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {twoThirdFountain && twoThirdFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
               
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>3 CARD SERIES (CRACKLING WITH COLOUR)
                    </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {twoThirdCardSeries && twoThirdCardSeries.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                        {electric && electric.map((item) => (
                            <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                        ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>4" SPECIAL FOUNTAIN (5 Pcs)</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fourSpecialFountain && fourSpecialFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {fourSpecialFountain && fourSpecialFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>8" FOUNTAIN</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eightFountain && eightFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {eightFountain && eightFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>

                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>FANCY CRACKERS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fancyCrackers && fancyCrackers.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {fancyCrackers && fancyCrackers.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>ROCKETS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rockets && rockets.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {rockets && rockets.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>BOMB CRACKERS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bombCrackers && bombCrackers.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {bombCrackers && bombCrackers.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>AV CHOTTA FANCY</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {avChottaFance && avChottaFance.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {avChottaFance && avChottaFance.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>2” AV FANCY</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {twoavChottaFance && twoavChottaFance.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {twoavChottaFance && twoavChottaFance.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>2” AV AERIAL FANCY</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {twoavarielfancy && twoavarielfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {twoavarielfancy && twoavarielfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>3 ½” AV FANCY</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {threeHalfavfancy && threeHalfavfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {threeHalfavfancy && threeHalfavfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>4 1/4” AV FANCY</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fourFouravfancy && fourFouravfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {fourFouravfancy && fourFouravfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>4 ½” AV FANCY </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fourHalfavfancy && fourHalfavfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {fourHalfavfancy && fourHalfavfancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>4 ” AV DOUBLE BALL </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fourAVDoubleEball && fourAVDoubleEball.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {fourAVDoubleEball && fourAVDoubleEball.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>

               

            

               


               

               

                
                

                


                

                

                
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>5” SRI VIJAI FANCY </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fiveSriVijaiFancy && fiveSriVijaiFancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {fiveSriVijaiFancy && fiveSriVijaiFancy.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>


                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>SPEED MULTICOLOUR SHOTS </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {speedMultiColorShot && speedMultiColorShot.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {speedMultiColorShot && speedMultiColorShot.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>

                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>SPARKLERS </u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sparklers && sparklers.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {sparklers && sparklers.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>

                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>COLOUR MATCHES</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colorMatches && colorMatches.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {colorMatches && colorMatches.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))} 
                    </div> */}
                </div>

                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>NEW ARRIVAL</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newArrival && newArrival.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {newArrival && newArrival.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>

                <div className=' pt-3 '>
                    <h3 className='fw-bold lead secondary_color'><u>Laddu Special Fountain</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ladduSpecialFountain && ladduSpecialFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {ladduSpecialFountain && ladduSpecialFountain.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>NEW ARRIVAL ANANDA'S FIREWORKS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newArrivalAnaconda && newArrivalAnaconda.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {festival && festival.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                </div>

             
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>FESTIVAL CRACKERS</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {festival && festival.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='row'>
                    {festival && festival.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                    </div> */}
                    
                </div>
               
           
                <div className=' pt-3'>
                    <h3 className='fw-bold lead secondary_color'><u>AV PREMIUM GIFT BOXES (NET RATE)</u></h3>
                    <div className='pt-3 table_wrap'>
                        <table className="table table-bordered">
                            <thead>
                                <tr className=' text-center'>
                                    
                                    <th scope="col">Code</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Actual Rate (Rs.)</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col">Total (Rs.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {avpremiumgiftboxes && avpremiumgiftboxes.map((item) => (
                                    <ProductTableComponents item={item} key={item.code} getQuantity={getQuantity} totalAmount={totalAmount} />
                                ))}
                            </tbody>
                        </table>
                    </div>
</div>
             
            </div>

            <div className='checkout_parent'>
                <Link to='/checkout' className=' text-white'> <i className="fas fa-shopping-cart"></i></Link>
                <span className='checkout_product'>{totalProducts && totalProducts}</span>
            </div>
        </div>
    )
}

export default QuickPurchase