import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import diwali from '../assets/diwali-sale.png';
import twin from '../assets/twin.png';
import sun from '../assets/sun.png';
import logo from '../assets/logo.png';
import aso from '../assets/aso.png';
// import Footer from './Footer';
import slider1 from '../assets/banner1.webp';
import slider2 from '../assets/banner2.webp';
import { Link } from 'react-router-dom';
// import Price from './Price';

function Home() {

    const options = {
        items: 1,
        loop: true,
        nav: true,
        autoplay: true,
        autoplayTimeout: 4000,
        nav: false,
        dots: false,
        margin: 0,
        responsive: {
            1100: {
                items: 4,
            },
            724: {
                items: 3,
            },
            500: {
                items: 2,
            },
            370: {
                items: 1,
                innerwidth: "100%",
                outerWidth: "100%"
            }
        }
    };
    return (
        <div className='home'>
            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={slider1} class="d-block w-100" alt="Image 1" />
                    </div>
                    <div className="carousel-item">
                        <img src={slider2} class="d-block w-100" alt="Image 2" />
                    </div>
                </div>
                <a className="carousel-control-prev " href="#carouselExample" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </a>
                <a className="carousel-control-next " href="#carouselExample" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </a>
            </div>
            <div className='container'>
                <div className='row '>
                    <div className='col-lg-6 col-12'>
                        <h2 className='title primary_color'>About Us</h2>
                        <p>
                            Kaniagencies Fireworks is one of the leading crackers dealer in Sivakasi. Since 2010 we have the good relationship with our valuable customers. Here you can get all kinds of crackers with affordable prices. Around 11 years our customers trusted us. We supply the crackers all over the Southern India. Our Customer support will be avialble 24 x 7.
                        </p><br></br>
                        <p >With over 200 varieties of crackers developed and marketed every year, we are among the most sought brands in the Sivakasi region and around the country. We have been a wholesale and retail supply trader in firecrackers for about 11 years. Our products are known for their safety and we take great efforts to ensure that all our orders are delivered in a standard time frame with an economical pricing.</p>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <img src={diwali} className='img-responsive' />
                    </div>
                </div>
            </div>
            <div className='pink-bg'>
                <div className='container'>
                    <div className='row align-items-center purchase_row'>

                        <div className='col-md-8 '>
                            <h4 className='my-0'>Let us Celebrate the Diwali & With us</h4>
                        </div>
                        <div className='col-md-4  '>
                            <Link to='/quick-purchase' className='animated middle-fill inline-block'>QUICK PURCHASE</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='background dark-bg'>
                <div className='container h-100 pb-4'>
                <div className='section-title'>
                                <h2 className='pt-4'>The Best <span className='pink'>Crackers Shop</span> Ever</h2>
                            </div>
                    <div className='row h-100 pt-5 px-2 px-sm-3 px-md-2'>
                                <div className='col-sm-12 col-md-12 col-lg-6 position-relative'>
                                    <div className='feature-box'>
                                        <i class="fa-solid fa-certificate"></i>
                                        <h3>Best Quality</h3>
                                        <p className='mt-5 para'>The Brand you can trust safety is our priority</p>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6 position-relative'>
                                    <div className='feature-box'>
                                        <i class="fa-solid fa-clock"></i>
                                        <h3>24/7 Support</h3>
                                        <p className='mt-5 para'>We are what we repeatedly do. Excellence, is not <br></br>an act, but a habit.</p>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6  position-relative'>
                                    <div className='feature-box'>
                                        <i class="fa-solid fa-rocket"></i>
                                        <h3>Quick Delivery</h3>
                                        <p className='mt-5 para'>Get crackers delivered to your nearest Hub. On <br></br>time delivery</p>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-6  position-relative'>
                                    <div className='feature-box'>
                                        <i class="fa-solid fa-money-bill-1"></i>
                                        <h3>Easy Payments</h3>
                                        <p className='mt-5 para'>You can pay your bills easily and quickly.</p>
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 brand'>
                            <h2 className='d-flex justify-content-center mt-2 mt-sm-3 mt-md-4 secondary_color'>Our Brands</h2>
                        </div>
                        <div className='container mt-5'>
                            <OwlCarousel className='owl-theme' {...options} loop margin={10} nav>

                                <div class="card gap-3" style={{ width: '15rem' }}>
                                    <div className='item'>
                                        <img src={aso} class="card-img-top" height={120} width={150} />
                                    </div>
                                </div>
                                <div class="card" style={{ width: '15rem' }}>
                                    <div className='item'>
                                        <img src={sun} class="card-img-top" height={120} width={150} />
                                    </div>
                                </div>
                                <div class="card" style={{ width: '15rem' }}>
                                    <div className='item'>
                                        <img src={twin} class="card-img-top" height={120} width={150} />
                                    </div>
                                </div>
                                <div class="card" style={{ width: '15rem' }}>
                                    <div className='item'>
                                        <img src={logo} class="card-img-top" height={120} width={150} />
                                    </div>
                                </div>


                            </OwlCarousel>

                        </div>
                    </div>
                </div >
            </section >
            {/* <Price/> */}
            {/* <Footer /> */}
        </div >
    )
}

export default Home