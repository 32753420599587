import React from 'react'

const PrintComponent = ({item}) => {
    const value= item.price * item.quantity
  return (
    <tr >
    <td className='fw-normal'>{item.code}</td>
    <td>{item.productName}</td>
  
    <td className='text-danger fw-bolder'><s>{item.actualRate}</s></td>
    <td className=' text-success fw-bolder'>₹ {item.price}</td>
    <td>{item.quantity}</td>
    <td>{value}</td>
</tr>
  )
}

export default PrintComponent