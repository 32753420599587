import React from 'react';
import '../styles/contact.css';
import { useState } from 'react';

function Contact() {
    const [showError, setShowerror] = useState(false);
    const [inputvalue, setInputvalue] = useState();

    const handleemail = (e) => {
        setInputvalue(e.target.value);
        if (inputvalue.trim() === '') {
            setShowerror(true)
        }
    }

    return (
        <div>
            <div className='contact bg bg-fixed bg-overlay-black-70'>

                <div className='contact-inner mt-5'>
                    <div className='contact-title mt-5'>
                        <h3>Contact Us</h3>
                    </div>
                    <div className='contactlist mt-4'>
                        <ul>
                            <li><i class="fa-solid fa-house"></i><span>Home</span></li>
                            <li><i class="fa-solid fa-angles-right"></i></li>
                            <li>Pages</li>
                            <li><i class="fa-solid fa-angles-left"></i></li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container mt-5 '>
                <div className='touch d-flex justify-content-center'>
                    <h3>Get In Touch With Us</h3>
                </div>
                <div className='row  contact-info mt-3 py-2 py-sm-3 py-md-4'>
                    <div className='row text-white'>
                        <div className='col-12 col-md-12 col-lg-4 position-relative  pt-2 pt-sm-3 pt-md-3 pt-lg-0'>
                            <div className=' row'>
                                <span className='col-2 col-md-1 col-lg-3 h3'><i class="fa-solid fa-house-circle-exclamation"></i></span>
                                <div className='col-10 col-md-10 col-lg-9'>
                                    <h3>Location:</h3>
                                    <span>Door number 4/459-19,<br></br>
                                        Amathur,<br></br>
                                        Virudhunagar to Sivakasi main road.
                                    </span></div>
                            </div>
                        </div>
                        <div className='col-12 col-md-12 col-lg-4 position-relative pt-2 pt-sm-3 pt-md-3 pt-lg-0'>
                            <div className=' row'>
                                <span className='col-2 col-md-1 col-lg-3 h3'><i class="fa-solid fa-phone-volume"></i></span>
                                <div className='col-10 col-md-10 col-lg-9'>
                                    <h3>Phone:</h3>
                                    <span><a href='tel:+918144540196' className=' text-white'> (+91) 8144540196</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-12 col-lg-4 position-relative pt-2 pt-sm-3 pt-md-3 pt-lg-0'>
                            <div className=' row'>
                                <span className='col-2 col-md-1 col-lg-3 h3'><i class="fa-solid fa-house-circle-exclamation"></i></span>
                                <div className='col-10 col-md-10 col-lg-9'><h3>Mail:</h3>
                                    <span><a href='mailto:karthibala003@gmail.com' className=' text-white'>karthibala003@gmail.com</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='map mt-3 mt-sm-4 mt-md-5'>
                <div className='row px-2 px-sm-3 px-md-4'>
                    <div className='col-lg-6 py-3 py-sm-4'>
                        <div className='title-map'>
                            <h4>Send Us Message</h4>
                        </div>
                        <form className='form'>
                            <div className='form-group mt-3'>
                                <label>Email Id*</label>
                                <div className='input-group'>
                                    <input type='email' placeholder='email' formcontrolname='form' className='form-control ng-invalid' onChange={handleemail} value={inputvalue} />
                                </div>
                                {/* <div className='error'><p>Email Id is required</p></div> */}
                                {showError && (
                                    <span style={{ color: 'red' }}>Input cannot be empty!</span>
                                )}
                            </div>
                            <div className='form-group mt-3'>
                                <label>Subject*</label>
                                <div className='input-group'>
                                    <input type='email' placeholder='email' formcontrolname='form' className='form-control ng-invalid' />

                                </div>
                                {/* <div className='error'><p>Subject is required</p></div> */}

                            </div>
                            <div className='form-group mt-3'>
                                <label>Message*</label>
                                <div className='input-group'>
                                    <textarea placeholder='email' formcontrolname='form' className='form-control ng-invalid' />

                                </div>
                                {/* <div className='error'><p>Subject is required</p></div> */}
                            </div>
                            <div>
                                <input type='submit' value='Submit' className='but' />
                            </div>
                        </form>

                    </div>
                    <div className='col-lg-6'>
                    <iframe className='map_frame' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3934.300270155208!2d77.8709393750256!3d9.569363690515123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwMzQnMDkuNyJOIDc3wrA1MicyNC43IkU!5e0!3m2!1sen!2sin!4v1696573947496!5m2!1sen!2sin" width="100%"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact